/*
 *
 *  Login
 *  contains basic js for the contact page
 *  !! Created by Jurgen on 29/08/2017
 *
 */

$(document).ready(function () {
    // Initialize strTypeLogin (login | loginpopup)
    if (typeof strPage === 'undefined') {
        strPage = 'login';
    }

    // Check if login or register
    if (strPage == 'login') {
        // LOGIN
        // Initialize strTypeLogin (login | loginpopup)
        if (typeof strTypeLogin === 'undefined') {
            strTypeLogin = 'login';
        }

        // Login
        FormValidateLogin($('form#frmLogin'), strTypeLogin);

        // login popup
        // Close popup when you are already loggedin
        // if (blnClosePopup) {
        //     parent.$.fancybox.close();
        // }
    } else {
        // Register
        // Remove company fields
        // $('form .company').hide();

        // Clicking on 'Particulier' => hide company fields
        $('form input[id="optVatTypeP"]').on('click', function () {
            $('form .company').slideUp();
            // Remove all errors
            $('form .company input').removeClass('required error');
            $('form .company input').find('label.error').remove();
        });

        // Clicking on 'Bedrijf' => show company fields
        $('form input[id="optVatTypeB"]').on('click', function () {
            $('form .company').slideDown();
            $('form .company input').addClass('required');
        });

        if (WebsiteSettingBlnUseState) {
            // Change the country
            $('form select[id="selCountry"]').on('change', function () {
                var strParams = 'strCountryCode=' + $(this).val();

                // Get states from the selected country
                $.post(strAjaxPath + 'cart/get-state.php', strParams, function (arrObjecten) {
                    var intNumber = Object.keys(arrObjecten).length;

                    if (intNumber > 0) {
                        // Show areas
                        $('#area-container').removeClass('d-none');
                        $('#selState').find('option').remove();

                        $.each(arrObjecten, function (i, val) {
                            $('#selState').append(new Option(val.areaname, val.areacode));
                        });
                    } else {
                        // Hide areas
                        $('#area-container').addClass('d-none');
                        $('#selState').find('option').remove();
                    }
                }, 'json');
            });
        }

        // Form validate
        FormValidateRegister($('form#frmRegister'));

        // $('input[type="submit"]').click(function (event) {
        $('#btnRegister').on("click", function (event) {
            event.preventDefault();
            FormValidateRegister($('form#frmRegister'));
            if ($('form#frmRegister').valid()) {
                grecaptcha.execute();
            }
        });
    }
});

// // Use login.js
// $.getScript(strPath + 'javascript/default/base/pages/login.js', function () {
//
// });